<template>
  <moe-page title="热门搜索">
    <moe-inquire @search="handleHotSearch">
      <el-form-item label="热门搜索名称">
        <el-input placeholder="输入热门搜索名称" v-model="hotSearchParams.name" clearable />
      </el-form-item>
    </moe-inquire>


    <moe-table ref="hotSearchTable" url="/shop/search/page" :params="hotSearchParams" :number-show="false" row-key="id">
      <template slot="tool">
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="$router.push('/hotSearch/add')">添加热门搜索</el-button>
      </template>
      <el-table-column prop="name" label="活动名称" min-width="150" :show-overflow-tooltip="true" />
      <el-table-column prop="remark" label="备注" min-width="150" :show-overflow-tooltip="true" />
      <el-table-column prop="sort" label="排序" width="50" />
      <el-table-column label="创建时间" min-width="150">
        <template slot-scope="{ row }">
          {{ $moe_time.getTimeStamp(row.createTime) }}
        </template>
      </el-table-column>
      <el-table-column prop="status" label="是否展示" width="90">
        <template slot-scope="{ row }">
          <el-tag type="danger" v-if="row.status == 2" @click="handleShelf(row, 1, '启用')"><i class="el-icon-unlock" />禁用</el-tag>
          <el-tag type="success" v-else-if="row.status == 1" @click="handleShelf(row, 2, '禁用')"><i class="el-icon-lock" />启用</el-tag>
          <el-tag type="info" v-else>无效</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="400" fixed="right">
        <div class="moe-table__btns" slot-scope="{ row }">
          <el-button type="warning" size="mini" icon="el-icon-edit" @click="$router.push(`/hotSearch/add?id=${row.id}`)">编辑</el-button>
          <el-button type="danger" size="mini" icon="el-icon-delete" @click="handleDelete(row)">删除</el-button>
        </div>
      </el-table-column>
    </moe-table>
  </moe-page>
</template>

<script>
export default {
  name: 'HotSearch',
  data() {
    return {
      hotSearchParams: {
        pageNum: 1,
        pageSize: 10,
        name: ''
      }
    }
  },
  methods: {
    handleHotSearch(isSearch) {
      if (!isSearch) {
        this.hotSearchParams = {
          pageNum: 1,
          pageSize: 10,
          name: '',
        };
      }
      //刷新商品表格
      this.$refs['hotSearchTable'].searchData();
    },
    /** 热门搜索上下架 */
    handleShelf({ name, id }, status, text) {
      this.$moe_layer.confirm(`您确定要“${text}”当前选项“${name}”吗？`, () => {
        this.$moe_api.GOODS_API.searchShelf({ id, status }).then((data) => {
          if (data.code === 200) {
            this.$moe_msg.success('操作成功');
            this.$refs['hotSearchTable'].searchData();
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      })
    },
    /** 删除热门搜索 */
    handleDelete({ name, id }) {
      this.$moe_layer.confirm(`您确定要删除当前选项“${name}”吗？`, () => {
        this.$moe_api.GOODS_API.searchDelete({ id }).then((data) => {
          if (data.code === 200) {
            this.$moe_msg.success('删除成功');
            this.$refs['hotSearchTable'].searchData();
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      })
    }
  }
}
</script>